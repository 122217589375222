import { Note } from '../../incidents/incidents.model';

export class GetAdmissionsList {
  static readonly type = '[Admissions] Get admissions list';
}

export class GetAdmissionsGroupByPatients {
  static readonly type = '[Admissions] Get admissions group By Patient';
}

export class GetAdmissionById {
  static readonly type = '[Admissions] Get admission by id';

  constructor(
    public id: string | number,
    public discharge: boolean,
  ) {}
}

export class GetPeriodListByPatientId {
  static readonly type = '[Admissions] Get Periods List By PatientId';

  constructor(
    public patientId: string | number,
    public params,
  ) {}
}

export class GetAdmissionListByPeriodId {
  static readonly type = '[Admissions] Get Admission List By Period Id';

  constructor(
    public periodId: string | number,
    public params: any = {},
  ) {}
}

export class ClearAdmissionPeriod {
  static readonly type = '[Admissions] Clear admission period li';
}

export class UpdateSupervisor {
  static readonly type = '[Admissions] Update supervisor';

  constructor(
    public id: string,
    public supervisorId: string,
  ) {}
}

export class UpdateStartDate {
  static readonly type = '[Admissions] Update start date';

  constructor(
    public id: string,
    public startDate: string,
  ) {}
}

export class UpdatePortalSOCDateCell {
  static readonly type = '[Admissions] UpdatePortalSOCDateCell';

  constructor(
    public id: string,
    public portalSOCDate: string,
  ) {}
}

export class GetAdmissionStatusList {
  static readonly type = '[Admissions] Get status list';
}

export class GetAdmissionsListType {
  static readonly type = '[Admissions] Get types list';
}

export class DownloadAdmission {
  static readonly type = '[Admissions] Download admissions';

  constructor(
    public id: string,
    public format: 'pdf' | 'zip',
  ) {}
}

export class ChangeAdmissionCreatedDate {
  static readonly type = '[Admissions] Change Admission Created Date';

  constructor(
    public id: string,
    public startDate: string,
    public typeDate: string,
  ) {}
}

export class SetPage {
  static readonly type = '[Admissions] Set page';

  constructor(public page: number) {}
}

export class SetPerPage {
  static readonly type = '[Admissions] Set per page';

  constructor(public perPage: number) {}
}

export class GetSupervisors {
  static readonly type = '[Admissions] Get supervisors';

  constructor(public search?: string) {}
}

export class GetUsersForPoc {
  static readonly type = '[Admissions] Get users for poc';

  constructor(public search?: string) {}
}

export class GetAdmissionCaregivers {
  static readonly type = '[Admissions] Get supervisors';

  constructor(public search?: string) {}
}

export class SyncHHA {
  static readonly type = '[Admissions] Sync HHA';
}

export class GetAdmissionSyncStatus {
  static readonly type = '[Admissions] Get sync status';
}

// NOTES

export class GetNotesListByAdmissionId {
  static readonly type = '[Admission Notes] Get Notes List By AdmissionId';

  constructor(
    public id: string,
    public filters?: any,
  ) {}
}

export class GetAdmissionsNotesById {
  static readonly type = '[GetAdmissionsNotesById] Get admission notes by id';

  constructor(
    public id: string,
    public filters?: any,
  ) {}
}

export class GetAdmissionsAllNotesById {
  static readonly type = '[GetAdmissionsAllNotesById] Get all admission notes by id';

  constructor(
    public id: string,
    public filters?: any,
  ) {}
}

export class SetAdmissionsNotesById {
  static readonly type = '[SetAdmissionsNotesById] Set admission notes by id';

  constructor(public data: { id: string; text: string; reason?: number }) {}
}

export class UpdateAdmissionsNotesById {
  static readonly type = '[UpdateAdmissionsNotesById] Update Admissions notes by id';

  constructor(public data: { id: string; note: Note; admissionId: string; reason?: number }) {}
}

export class DelAdmissionsNotesById {
  static readonly type = '[DelAdmissionsNotesById] Del Admissions notes by id';

  constructor(public data: { id: string; uuid: string }) {}
}

export class GetPatientNoteReasonsByPatientId {
  static readonly type = '[GetPatientAdmissionNoteReasonsByPatientId] Get Patient Note Reasons';

  constructor(public id: string) {}
}

export class GetPatientNoteReasons {
  static readonly type = '[GetPatientAdmissionNoteReasons] Get Patient Note Reasons';

  constructor(
    public id: string,
    public discharge: boolean,
  ) {}
}

export class SetAdmissionsNotePage {
  static readonly type = '[SetAdmissionsNotePage] Set page';

  constructor(public page: number) {}
}

export class SetAdmissionsNotePageScroll {
  static readonly type = '[SetAdmissionsNotePageScroll] Set page scroll ';

  constructor(public page: number) {}
}

export class SetAdmissionsDetailsPage {
  static readonly type = '[SetAdmissionsDetailsPage] Set admissions details page';

  constructor(public page: number) {}
}

export class GetAdmissionsNotesByAdmissionId {
  static readonly type = '[GetAdmissionsNotesByAdmissionId] Get admission notes by admissionId';

  constructor(public admissionId: string) {}
}

export class GetAdmissionDetails {
  static readonly type = '[GetAdmissionDetails] Get admission for a patient';

  constructor(
    public patientId: string,
    public filters?: any,
  ) {}
}

export class UpdateAdmissionsNotesByAdmissionId {
  static readonly type = '[UpdateAdmissionsNotesByAdmissionId] Update incident notes by id';

  constructor(public data: { id: string; note: Note; reason?: number }) {}
}

export class DelAdmissionsNotesByAdmissionId {
  static readonly type = '[DelAdmissionsNotesByAdmissionId] Del Admissions notes by id';

  constructor(public data: { id: string; uuid: string }) {}
}

export class ClearAdmissionNotes {
  static readonly type = '[ClearAdmissionNotes] Clear Admission Notes';
}

export class GenerateAdmission {
  static readonly type = '[GenerateAdmission] Generate new admission';

  constructor(
    public id: string,
    public type: string,
  ) {}
}

export class GeneratePOC {
  static readonly type = '[GenerateAdmission] Generate new poc';

  constructor(
    public id: string,
    public type: string,
    public payload: any,
  ) {}
}

export class ValidatePOC {
  static readonly type = '[GenerateAdmission] Validate new poc';

  constructor(
    public id: string,
    public type: string,
    public payload: any,
  ) {}
}

export class DeleteAdmission {
  static readonly type = '[DeleteAdmission] Delete an admission';

  constructor(public id: string) {}
}

export class ChangeAdmissionOffice {
  static readonly type = '[ChangeAdmissionOffice] Change Admission Office';

  constructor(
    public id: string,
    public departmentId: number,
  ) {}
}

export class ChangeAdmissionSignDate {
  static readonly type = '[ChangeAdmissionSignDate] Change Admission Sign Date';

  constructor(
    public id: string,
    public date: string,
  ) {}
}

export class SetAssignedUserAdmission {
  static readonly type = '[SetAssignedUser] Set user to assigned Admission';

  constructor(
    public id: string[],
    public users: string[],
    public assignAllDocuments?: number,
  ) {}
}

export class DeleteAssignedUserAdmission {
  static readonly type = '[DeleteAssignedUser] Delete user to assigned Admission';

  constructor(
    public admissionId: string,
    public userId: string,
    public assignAllDocuments?: number,
  ) {}
}

export class GetUserListToAssignAdmission {
  static readonly type = '[GetAssignedUser] Get user to assigned Admission';

  constructor(public search?: string) {}
}

export class GetUserListToAssignFilterAdmission {
  static readonly type = '[GetAssignedUser] Get user to assigned Filter Admission';

  constructor(public search?: string) {}
}

export class EnableDatatableLoading {
  static readonly type = '[Admission] EnableLoading';
}

export class ResetFilters {
  static readonly type = '[Admission] Reset filter';

  constructor(public search?: string) {}
}

export class ResetDatatableLoading {
  static readonly type = '[Admission] Reset Reset Datatable Loading';
}
