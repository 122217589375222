import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import {
  DelAdmissionsNotesById,
  DownloadAdmission,
  GetAdmissionById,
  GetAdmissionsNotesById,
  GetAdmissionStatusList,
  GetSupervisors,
  SetAdmissionsNotesById,
  SetPerPage,
  SyncHHA,
  UpdateAdmissionsNotesById,
  UpdateStartDate,
  UpdateSupervisor,
  GetAdmissionsList,
  GetAdmissionSyncStatus,
  SetAdmissionsNotePage,
  GetAdmissionsNotesByAdmissionId,
  ClearAdmissionNotes,
  GetPatientNoteReasons,
  UpdateAdmissionsNotesByAdmissionId,
  DelAdmissionsNotesByAdmissionId,
  GetAdmissionDetails,
  GenerateAdmission,
  SetAdmissionsDetailsPage,
  DeleteAdmission,
  ChangeAdmissionCreatedDate,
  GetAdmissionsAllNotesById,
  SetAdmissionsNotePageScroll,
  ChangeAdmissionOffice,
  ChangeAdmissionSignDate,
  GetPatientNoteReasonsByPatientId,
  GetAdmissionsListType,
  SetAssignedUserAdmission,
  GetUserListToAssignAdmission,
  DeleteAssignedUserAdmission,
  GetUserListToAssignFilterAdmission,
  EnableDatatableLoading,
  GeneratePOC,
  GetAdmissionCaregivers,
  UpdatePortalSOCDateCell,
  GetUsersForPoc,
  GetPeriodListByPatientId,
  GetAdmissionListByPeriodId,
  GetNotesListByAdmissionId,
  ResetDatatableLoading,
  GetAdmissionsGroupByPatients,
  ValidatePOC,
  ClearAdmissionPeriod,
} from './admissions.actions';
import { AdmissionsService } from '../../services/admissions.service';
import { UserService } from '../../services/user.service';
import { Admission } from '../../admissions/admissions.model';
import { Note } from '../../incidents/incidents.model';
import { OpenMessagePopup } from '../dashboard.actions';
import { Injectable } from '@angular/core';
import { StaticDataService } from 'src/app/static-data.service';
import { Reason } from '../patients/patients-list/patients.state';
import { openLink } from '../../../shared/helpers/open-link';
import { Observable, Subscription, timer } from 'rxjs';
import { concatenateStrSeparatedByCommas, removeEmptyObjectValues } from '../../../shared/helpers/other';
import { convertMomentDate } from '../../../shared/helpers/date-format';
import { AdmissionDetailsFilter, PER_PAGE_KEY } from '../filters/filters.models';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';
import { IdNameObject } from '../../../shared/models';
import { replaceFields } from '../../../shared/helpers/replace-fields-object';

export interface AdmissionDto {
  address: string;
  city: string;
  dob: string;
  id: string;
  patient: string;
  patientId: string;
  phone: string;
  physicianName: string;
  physicianPhone: string;
  ssn: string;
  startDate: string;
  state: string;
  status: { id: number; title: string };
  supervisor: { id: string; name: string };
  type: string;
  zip: string;
}

export interface PeriodAdmission {
  id: string;
  startDate: string;
  endDate: string;
  number: number;
}

export interface AdmissionsStateModel {
  periodsList: PeriodAdmission[];
  list: AdmissionDto[];
  listByPeriod: { [key: string]: AdmissionDto[] };
  totalByPeriod: { [key: string]: number };
  isLoadingListByPeriod: { [key: string]: boolean };
  totalPeriods: number;
  totalNotes: number;
  notes: any[];
  admissionNotes: AdmissionDto[];
  admissionAllNotes: AdmissionDto[];
  usersForPOC: IdNameObject[];
  userList: [];
  userListFilter: [];
  caregiversFilter: Array<{ name: string }>;
  perPage: number;
  total: number;
  query: string;
  type: string;
  notePage: number;
  notePageScroll: number;
  reason: Reason[];
  detailsPageAdm: number;
  statuses: any[];
  statusList: any[];
  supervisorsList: any[];
  startDate: string;
  listAdmissionPatient: any[];
  totalAdmissionPatient: number;
  currentAdmission: any;
  types: AdmissionTypes[];
  isLoading: boolean;
}

export interface AdmissionTypes {
  id: number;
  title: string;
  key: string;
}

export const defaultState: AdmissionsStateModel = {
  periodsList: [],
  list: [],
  listByPeriod: {},
  totalByPeriod: {},
  isLoadingListByPeriod: {},
  totalNotes: 0,
  notes: [],
  totalPeriods: 0,
  admissionNotes: null,
  admissionAllNotes: null,
  userList: [],
  userListFilter: [],
  caregiversFilter: [],
  usersForPOC: [],
  notePage: 1,
  notePageScroll: 1,
  detailsPageAdm: 1,
  perPage: 10,
  total: 0,
  query: '',
  type: '',
  statuses: [],
  statusList: [],
  supervisorsList: [],
  startDate: '',
  reason: [],
  listAdmissionPatient: [],
  totalAdmissionPatient: 0,
  currentAdmission: {},
  types: [],
  isLoading: true,
};

@State({
  name: 'admissions',
  defaults: defaultState,
})
@Injectable()
export class AdmissionsState {
  constructor(
    private service: AdmissionsService,
    private userService: UserService,
    private staticService: StaticDataService,
    private store: Store,
  ) {}

  @Selector()
  static isLoading(state): Observable<boolean> {
    return state.isLoading;
  }

  @Selector()
  static listPeriods(state): Observable<boolean> {
    return state.periodsList;
  }

  @Selector()
  static totalPeriods(state): Observable<boolean> {
    return state.totalPeriods;
  }

  @Selector()
  static isLoadingListByPeriod(periodId: string) {
    return createSelector(
      [AdmissionsState], // Array of selector functions
      state => {
        return state.admissions.isLoadingListByPeriod[periodId];
      },
    );
  }

  @Selector()
  static listByPeriod(periodId: string) {
    return createSelector(
      [AdmissionsState], // Array of selector functions
      state => {
        return state.admissions.listByPeriod[periodId];
      },
    );
  }

  @Selector()
  static totalByPeriod(periodId: string) {
    return createSelector([AdmissionsState], state => {
      return state.admissions.totalByPeriod[periodId];
    });
  }

  @Selector()
  static list(state): Observable<any> {
    return state.list;
  }

  @Selector()
  static admissionNotes(state): Observable<any> {
    return state.admissionNotes;
  }

  @Selector()
  static admissionAllNotes(state): Observable<any> {
    return state.admissionAllNotes;
  }

  @Selector()
  static total(state): Observable<number> {
    return state.total;
  }

  @Selector()
  static page(state): Observable<number> {
    return state.page;
  }

  @Selector()
  static types(state): AdmissionTypes[] {
    return state.types;
  }

  @Selector()
  static statuses(state): Observable<any> {
    return state.statusList;
  }

  @Selector()
  static usersToAssign(state): Observable<any> {
    return state.userList;
  }

  @Selector()
  static usersToAssignFilter(state): Observable<any> {
    return state.userListFilter;
  }

  @Selector()
  static caregiversFilter(state): Observable<Array<{ name: string }>> {
    return state.caregiversFilter;
  }

  @Selector()
  static supervisors(state): Observable<any> {
    return state.supervisorsList;
  }

  @Selector()
  static usersForPOC(state): Observable<any> {
    return state.usersForPOC;
  }

  @Selector()
  static notePage(state): Observable<number> {
    return state.notePage;
  }

  @Selector()
  static notePageScroll(state): Observable<any> {
    return state.notePageScroll;
  }

  @Selector()
  static totalNotes(state): Observable<number> {
    return state.admissionNotes.totalNotes;
  }

  @Selector()
  static detailsPageAdm(state): Observable<any> {
    return state.detailsPageAdm;
  }

  @Selector()
  static listAdmissionPatient(state): Observable<any> {
    return state.listAdmissionPatient;
  }

  @Selector()
  static totalAdmissionPatient(state): Observable<number> {
    return state.totalAdmissionPatient;
  }

  @Selector()
  static reason(state): Observable<any> {
    return state.reason;
  }

  getFilters(filters: any): any {
    const {
      search,
      page,
      perPage,
      statuses,
      portalPatientStatuses,
      type,
      assigned,
      supervisors,
      startDate,
      endDate,
      order,
      dir,
      caregivers,
      coordinators,
    } = filters;
    const params = {
      page,
      perPage,
      order,
      dir,
      search,
      startDate: startDate ? convertMomentDate(startDate) : '',
      endDate: endDate ? convertMomentDate(endDate) : '',
      'statuses[]': statuses,
      'supervisors[]': supervisors?.length ? supervisors : [],
      'portalPatientStatuses[]': portalPatientStatuses,
      'assigned[]': assigned,
      'type[]': type,
      'caregivers[]': caregivers,
      'coordinators[]': coordinators,
    };
    return removeEmptyObjectValues(params);
  }

  @Action(GetPeriodListByPatientId)
  getPeriodListByPatientId(ctx: StateContext<AdmissionsStateModel>, { patientId, params }) {
    return this.service.getPeriodsList(patientId, params).pipe(
      tap(({ data, total }: any) => {
        ctx.patchState({
          periodsList: data,
          totalPeriods: total,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(GetAdmissionListByPeriodId)
  getAdmissionListByPeriodId(ctx: StateContext<AdmissionsStateModel>, { periodId, params }) {
    const state = ctx.getState();
    ctx.patchState({
      isLoadingListByPeriod: { ...state.isLoadingListByPeriod, [periodId]: true },
    });
    const filter = replaceFields(params);
    return this.service.getListByPeriodsId(periodId, filter).pipe(
      tap(({ data, total }: any) => {
        ctx.patchState({
          listByPeriod: { ...state.listByPeriod, [periodId]: data },
          totalByPeriod: { ...state.totalByPeriod, [periodId]: total },
        });
      }),
      finalize(() => {
        this.disableDataPeriodTableLoading(ctx, periodId);
      }),
    );
  }

  @Action(ClearAdmissionPeriod)
  clearAdmissionPeriod(ctx: StateContext<AdmissionsStateModel>) {
    ctx.patchState({
      periodsList: [],
    });
  }

  @Action(GetAdmissionsList)
  getList(ctx: StateContext<AdmissionsStateModel>, refresh?: any): Observable<any> {
    ctx.patchState({
      isLoading: true,
    });
    if (!refresh) {
      ctx.patchState({
        perPage: this.store.selectSnapshot(({ app }) => app.perPages?.find(per => per?.key === PER_PAGE_KEY.admissionsAll)?.perPage),
      });
    }
    const filtersValue = this.store.selectSnapshot(({ filters }) => filters.admissionsAll.model);
    const obj = {
      ...this.getFilters(filtersValue),
    };
    return this.service
      .getList({
        ...obj,
      })
      .pipe(
        tap(({ data, total }: any) => {
          ctx.patchState({
            list: data.map((item: any) => ({ ...item, signed: item.status.title === 'Signed' })),
            total,
          });
        }),
        finalize(() => {
          this.disableDataTableLoading(ctx);
        }),
      );
  }

  @Action(GetAdmissionsGroupByPatients)
  getListGroupByPatients(ctx: StateContext<AdmissionsStateModel>, refresh?: any): Observable<any> {
    ctx.patchState({
      isLoading: true,
    });
    if (!refresh) {
      ctx.patchState({
        perPage: this.store.selectSnapshot(({ app }) => app.perPages?.find(per => per?.key === PER_PAGE_KEY.admissions)?.perPage),
      });
    }
    const filtersValue = this.store.selectSnapshot(({ filters }) => filters.admissions.model);
    const obj = {
      ...this.getFilters(filtersValue),
    };
    return this.service
      .getListByPatient({
        ...obj,
      })
      .pipe(
        tap(({ data, total }: any) => {
          ctx.patchState({
            list: data.map((item: any) => ({ ...item, signed: item.status.title === 'Signed' })),
            total,
          });
        }),
        finalize(() => {
          this.disableDataTableLoading(ctx);
        }),
      );
  }

  @Action(GetAdmissionDetails)
  getAdmissionDetails(ctx: StateContext<AdmissionsStateModel>, { patientId }: GetAdmissionDetails): Observable<any> {
    ctx.patchState({
      isLoading: true,
    });
    const filtersValue: AdmissionDetailsFilter = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.AdmissionDetails].model);
    const payload = {
      ...filtersValue,
      startDate: filtersValue.startDate ? convertMomentDate(filtersValue.startDate) : '',
      endDate: filtersValue.endDate ? convertMomentDate(filtersValue.endDate) : '',
      page: filtersValue.page,
      'type[]': filtersValue?.type,
      'statuses[]': filtersValue?.statuses,
      'supervisors[]': filtersValue?.supervisors,
      'assigned[]': filtersValue?.assigned,
    };
    delete payload['statuses'];
    delete payload['supervisors'];
    delete payload['assigned'];
    delete payload['type'];

    return this.service.getAdmissionDetails(patientId, payload).pipe(
      tap(({ listAdmissionPatient, totalAdmissionPatient }: any) => {
        ctx.patchState({
          list: listAdmissionPatient.map((item: any) => {
            return {
              ...item,
              documents: concatenateStrSeparatedByCommas(item.documents),
            };
          }),
          totalAdmissionPatient,
        });
      }),
      finalize(() => {
        this.disableDataTableLoading(ctx);
      }),
    );
  }

  @Action(UpdateSupervisor)
  updateSupervisor(ctx: StateContext<AdmissionsStateModel>, { id, supervisorId }: UpdateSupervisor): Observable<any> {
    return this.service.setSupervisor(id, supervisorId);
  }

  @Action(UpdateStartDate)
  updateStartDate(ctx: StateContext<AdmissionsStateModel>, { id, startDate }: UpdateStartDate): Observable<any> {
    return this.service.setStartDate(id, startDate);
  }

  @Action(UpdatePortalSOCDateCell)
  updatePortalSOCDateCell(ctx: StateContext<AdmissionsStateModel>, { id, portalSOCDate }: UpdatePortalSOCDateCell) {
    return this.service.updatePortalSOCDateCell(id, portalSOCDate);
  }

  @Action(GetAdmissionStatusList)
  getStatuses(ctx: StateContext<AdmissionsStateModel>): Observable<any> {
    return this.service.getStatuses().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          statusList: data,
        });
      }),
    );
  }

  @Action(GetAdmissionsListType)
  getTypes(ctx: StateContext<AdmissionsStateModel>): Observable<AdmissionTypes[]> {
    return this.service.getTypes().pipe(
      tap((data: AdmissionTypes[]) => {
        ctx.patchState({
          types: data,
        });
      }),
    );
  }

  @Action(ChangeAdmissionCreatedDate)
  changeAdmissionCreatedDate(ctx: StateContext<AdmissionsStateModel>, payload: ChangeAdmissionCreatedDate): Observable<any> {
    return this.userService.changeAdmissionCreatedDate(payload).pipe(take(1));
  }

  @Action(SetPerPage)
  setPerPage(ctx: StateContext<AdmissionsStateModel>, { perPage }: SetPerPage): void {
    ctx.patchState({
      perPage,
    });
    this.getList(ctx, {});
  }

  @Action(GetSupervisors)
  getSupervisors(ctx: StateContext<AdmissionsStateModel>, { search }: GetSupervisors): Observable<any[]> {
    return this.userService.getSupervisors(search).pipe(
      tap((data: any[]) => {
        ctx.patchState({
          supervisorsList: data,
        });
      }),
    );
  }

  @Action(GetUsersForPoc)
  getUsersForPoc(ctx: StateContext<AdmissionsStateModel>, { search }: GetUsersForPoc): Observable<IdNameObject[]> {
    return this.userService.getUsersForPOC(search).pipe(
      tap((data: any[]) => {
        ctx.patchState({
          usersForPOC: data,
        });
      }),
    );
  }

  @Action(GetAdmissionCaregivers)
  getAdmissionCaregivers(ctx: StateContext<AdmissionsStateModel>, { search }: GetAdmissionCaregivers) {
    return this.userService.getAdmissionCaregivers(search).pipe(
      tap((res: Array<{ name: string }>) => {
        ctx.patchState({
          caregiversFilter: res,
        });
      }),
    );
  }

  @Action(DownloadAdmission)
  downloadAdmission(ctx: StateContext<AdmissionsStateModel>, { id, format }: DownloadAdmission): Observable<any> {
    return this.service.download(id, format).pipe(
      tap(({ link }: any) => {
        if (link) {
          openLink(link);
        }
      }),
    );
  }

  @Action(GetAdmissionById)
  getAdmissionById(ctx: StateContext<AdmissionsStateModel>, { id, discharge }: GetAdmissionById): Observable<any> {
    return this.service.getById(id, discharge);
  }

  @Action(GetAdmissionsNotesById)
  getAdmissionsNotesById(ctx: StateContext<any>, { id, filters }): Observable<any> {
    const state = ctx.getState();
    const obj = {
      page: state.notePage,
      perPage: filters?.perPage || 10,
      notSavePerPage: filters?.notSavePerPage ? 1 : 0,
    };
    return this.service.getAdmissionsNotesById(id, obj).pipe(
      tap(({ data, totalNotes }) => {
        const list = ctx.getState().list.map(admission => {
          if (admission.id === id) {
            if (state.notePage > 1 && Object.values(data || {}).length) {
              admission.notes = [...admission.notes, ...Object.values(data || {})];
            } else if (Object.values(data || {}).length) {
              admission.notes = Object.values(data || {});
            } else if (totalNotes === 0) {
              admission.notes = Object.values(data || {});
            }
          }
          return admission;
        });
        ctx.patchState({
          list,
          listAdmissionPatient: list,
          notes: data,
          totalNotes,
        });
      }),
    );
  }

  @Action(GetNotesListByAdmissionId)
  GetNotesListByAdmissionId(ctx: StateContext<any>, { id, filters }): Observable<any> {
    const state = ctx.getState();
    const obj = {
      page: state.notePage,
      perPage: filters?.perPage || 10,
      notSavePerPage: filters?.notSavePerPage ? 1 : 0,
    };
    return this.service.getAdmissionsNotesById(id, obj).pipe(
      tap(({ data, totalNotes }) => {
        ctx.patchState({
          notes: data,
          totalNotes,
        });
      }),
    );
  }

  @Action(GetAdmissionsAllNotesById)
  getAdmissionsAllNotesById(ctx: StateContext<any>, { id, filters }): Observable<any> {
    const state = ctx.getState();
    const obj = {
      page: state.notePageScroll,
      perPage: filters?.perPage || 10,
      notSavePerPage: filters?.notSavePerPage ? 1 : 0,
    };
    return this.service.getAdmissionsNotesById(id, obj).pipe(
      tap(({ data, totalNotes }) => {
        let admissionAllNotes;
        if (state.notePageScroll > 1 && Object.values(data || {}).length) {
          admissionAllNotes = [...state.admissionAllNotes.data, ...Object.values(data || {})];
        } else if (Object.values(data || {}).length) {
          admissionAllNotes = Object.values(data || {});
        } else if (totalNotes === 0) {
          admissionAllNotes = Object.values(data || {});
        }
        ctx.patchState({
          admissionAllNotes: {
            data: admissionAllNotes,
            totalNotes,
          },
        });
      }),
    );
  }

  @Action(SetAdmissionsNotesById)
  setAdmissionsNotesById(ctx: StateContext<any>, { data }): Observable<any> {
    return this.service.setAdmissionsNotesById(data.id, data.text, data.reason).pipe(
      tap((value: Note) => {
        const list = ctx.getState().list.map(admission => {
          if (admission.id === data.id) {
            admission.note = value.text;
            if (admission.notes) {
              admission.notes.unshift(value);
            } else {
              admission.notes = [value];
            }
          }
          return admission;
        });
        ctx.patchState({
          list,
        });
      }),
    );
  }

  @Action(UpdateAdmissionsNotesById)
  updateAdmissionsNotesById(ctx: StateContext<any>, { data }): Observable<any> {
    return this.service.updateAdmissionsNoteById(data.id, data.note.id, data.note.text, data.note.reason).pipe(
      tap((value: Note) => {
        const list = ctx.getState().list.map(admission => {
          if (admission.id === data.id) {
            admission.notes = admission.notes.map(note => (note.id === value.id ? value : note));
            admission.note = admission.notes[0].text;
          }
          return admission;
        });
        ctx.patchState({
          list,
        });
      }),
    );
  }

  @Action(DelAdmissionsNotesById)
  deleteIncidentNotesById(ctx: StateContext<any>, { data }): Observable<any> {
    return this.service.deleteAdmissionsNotesById(data.id, data.uuid).pipe(
      tap(() => {
        const list = ctx.getState().list.map(admission => {
          if (admission.id === data.id) {
            if (admission.notes) {
              admission.notes = admission.notes.filter(item => item.id !== data.uuid);
              admission.note = admission.notes.length ? admission.notes[0].text : '';
            } else {
              admission.notes = ctx
                .getState()
                .notes.map(note => {
                  if (note.id !== data.uuid) {
                    return note;
                  }
                })
                .filter((note): boolean => {
                  return note != null;
                });
            }
          }
          return admission;
        });
        ctx.patchState({
          list,
        });
      }),
    );
  }

  @Action(SetAdmissionsNotePage)
  setAdmissionsNotePage(ctx: StateContext<any>, { page }: SetAdmissionsNotePage): any {
    return ctx.patchState({
      notePage: page,
    });
  }

  @Action(SetAdmissionsNotePageScroll)
  setAdmissionsNotePageScroll(ctx: StateContext<any>, { page }: SetAdmissionsNotePage): any {
    return ctx.patchState({
      notePageScroll: page,
    });
  }

  @Action(GetPatientNoteReasonsByPatientId)
  getPatientNoteReasonsByPatientId(ctx: StateContext<any>, { id }: any): Observable<any> {
    return this.staticService.getPatientNoteReason(id).pipe(
      tap((res: Reason[]) => {
        ctx.patchState({
          reason: res,
        });
      }),
    );
  }

  @Action(GetPatientNoteReasons)
  getPatientNoteReasons(ctx: StateContext<any>, { id, discharge }: any): Observable<any> {
    return this.service.getById(id, discharge).pipe(
      switchMap((incident: any) =>
        this.staticService.getPatientNoteReason(incident.patient.id).pipe(
          tap((res: Reason[]) => {
            ctx.patchState({
              reason: res,
            });
          }),
        ),
      ),
    );
  }

  @Action(SetAdmissionsDetailsPage)
  settAdmissionsDetailsPage(ctx: StateContext<any>, { page }: SetAdmissionsDetailsPage): any {
    return ctx.patchState({
      detailsPageAdm: page,
    });
  }

  @Action(SyncHHA)
  syncHHA(): Observable<any> {
    return this.service.sync().pipe(
      tap((data: { message: string; notifyUser: boolean }) => {
        this.store.dispatch(new OpenMessagePopup(data.message));
      }),
    );
  }

  @Action(GetAdmissionSyncStatus)
  getSyncStatus(ctx: StateContext<any>): Observable<any[]> {
    return this.service.getSyncStatus().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          syncStatus: data,
        });
      }),
    );
  }

  @Action(GetAdmissionsNotesByAdmissionId)
  getAdmissionsNotesByAdmissionId(ctx: StateContext<any>, { admissionId }): Subscription {
    return this.service.getAdmissionsNotesById(admissionId).subscribe((res: Admission[]) => {
      ctx.patchState({
        admissionNotes: res,
        admissionAllNotes: res,
        notePageScroll: 1,
      });
    });
  }

  @Action(UpdateAdmissionsNotesByAdmissionId)
  updateAdmissionsNotesByAdmissionId(ctx: StateContext<any>, { data }): Observable<any> {
    return this.service.updateAdmissionsNoteById(data.id, data.note.id, data.note.text, data.note.reason);
  }

  @Action(DelAdmissionsNotesByAdmissionId)
  delAdmissionsNotesByAdmissionId(ctx: StateContext<any>, { data }): Observable<any> {
    return this.service.deleteAdmissionsNotesById(data.id, data.uuid);
  }

  @Action(ClearAdmissionNotes)
  clearAdmissionNotes(ctx: StateContext<any>): void {
    ctx.patchState({
      admissionNotes: null,
      admissionAllNotes: null,
    });
  }

  @Action(GenerateAdmission)
  generateAdmission(ctx: StateContext<any>, { id, type }): Observable<any> {
    return this.service.generateAdmission(id, type).pipe(
      tap((data: any) => {
        ctx.patchState({
          currentAdmission: data,
        });
      }),
    );
  }

  @Action(GeneratePOC)
  generatePOC(ctx: StateContext<any>, { id, type, payload }): Observable<any> {
    return this.service.generatePOC(id, type, payload).pipe(
      tap((data: any) => {
        ctx.patchState({
          currentAdmission: data,
        });
      }),
    );
  }

  @Action(ValidatePOC)
  validatePOC(ctx: StateContext<any>, { id, type, payload }): Observable<any> {
    return this.service.validatePOC(id, type, payload).pipe(
      tap((data: any) => {
        ctx.patchState({
          currentAdmission: data,
        });
      }),
    );
  }

  @Action(DeleteAdmission)
  deleteAdmission(ctx: StateContext<any>, { id }: DeleteAdmission): Observable<void> {
    return this.service.deleteAdmission(id);
  }

  @Action(ChangeAdmissionOffice)
  changeAdmissionOffice(ctx: StateContext<any>, { id, departmentId }: ChangeAdmissionOffice): Observable<any> {
    return this.service.changeAdmissionOffice(id, departmentId);
  }

  @Action(ChangeAdmissionSignDate)
  changeAdmissionSignDate(ctx: StateContext<any>, { id, date }: ChangeAdmissionSignDate): Observable<any> {
    return this.service.changeAdmissionSignDate(id, date);
  }

  @Action(SetAssignedUserAdmission)
  setAssignedUser(ctx: StateContext<any>, { id, users, assignAllDocuments }: SetAssignedUserAdmission): Observable<any> {
    return this.service.setAssignedUser(id, users, assignAllDocuments);
  }

  @Action(GetUserListToAssignAdmission)
  getUserToAssign(ctx: StateContext<any>, { search }: GetUserListToAssignAdmission): Observable<any> {
    return this.service.getUserList(search, 0).pipe(
      tap(users => {
        ctx.patchState({
          userList: users,
        });
      }),
    );
  }

  @Action(GetUserListToAssignFilterAdmission)
  getUserToAssignFilter(ctx: StateContext<any>, { search }: GetUserListToAssignFilterAdmission): Observable<any> {
    return this.service.getUserList(search, 1).pipe(
      tap(users => {
        ctx.patchState({
          userListFilter: users,
        });
      }),
    );
  }

  @Action(DeleteAssignedUserAdmission)
  deleteAssignedUser(ctx: StateContext<any>, { admissionId, userId, assignAllDocuments }: DeleteAssignedUserAdmission): Observable<void> {
    return this.service.deleteAssignedUser(admissionId, userId, assignAllDocuments);
  }

  @Action(EnableDatatableLoading)
  enableDatatableLoading(ctx: StateContext<any>): void {
    ctx.patchState({
      isLoading: true,
    });
  }

  @Action(ResetDatatableLoading)
  resetLoadingTable(ctx: StateContext<any>): void {
    ctx.patchState({
      isLoadingListByPeriod: {},
    });
  }

  private disableDataTableLoading(ctx: StateContext<any>): void {
    timer(1000)
      .pipe(take(1))
      .subscribe(() => {
        ctx.patchState({
          isLoading: false,
        });
      });
  }

  private disableDataPeriodTableLoading(ctx: StateContext<any>, periodId): void {
    const state = ctx.getState();
    timer(1000)
      .pipe(take(1))
      .subscribe(() => {
        ctx.patchState({
          isLoadingListByPeriod: { ...state.isLoadingListByPeriod, [periodId]: false },
        });
      });
  }
}
